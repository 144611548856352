<template>
	<div>
		<div class="head">
			<div class="text">
				<!-- <img class="logo" :src="logo" alt=""> -->
				<!-- <div class="title">{{title}}</div> -->
			</div>
			<!-- <img :src="topImg" alt=""> -->
			<img src="../../assets/eventtheme/logo7.png" alt="">
		</div>
		<div class="navigation">
			<div class="piece" v-for="(item, index) in navList" :key="index" @click="goDingyue(item.name, item)">
				<p class="name">{{ item.name }}</p>
			</div>
		</div>
		<div class="return" @click="homepage">
			<img src="../../assets/image/logo.png" alt="">
			<p class="home">返回首页</p>
		</div>
		<div class="back-box">
			<div v-if="display" style="background: #fff;width: 1300PX;margin: 0 auto;margin-top: -50PX;">
				<div class="carousel" style="min-height: 459PX;">
					<div class="lun" v-if="cards && cards.length > 0">
						<carousel :imgList="cards" :type="1" width="1200" height="459PX" borderradius="0" @getclick="jump"
							background="rgb(0, 0, 0,0.38)"></carousel>
					</div>
				</div>

				<div class="statistics" v-if="allData && allData.length > 0" v-for="(item, index) in allData" :key="index">
					<div v-for="(res, index1) in item" :key="index1"
						v-if="item && item.length > 0 && res.name != '精彩瞬间' && res.name != '赛程安排' & res.name != '精彩图集'">

						<div class="box_4 flex-col" style="background: #fff;">
							<div class="section_14 flex-row justify-between">
								<div class="block_14 flex-col justify-between">
									<div class="group_2 flex-col"></div>
									<span class="text_7">{{ res.name }}</span>
								</div>
								<div class="section_3 flex-row justify-end" @click="goDingyue(res.name, res)">
									<span class="text_8">更多&gt;&gt;</span>
									<div class="group_3 flex-col"></div>
								</div>
							</div>
							<div class="text-wrapper_104 flex-row justify-between" v-for="(ess, index3) in res.articles" :key="index3"
								v-if="index3 < 7" @click="jump(ess.id)">
								<div class="text_9">{{ ess.title }}</div>
								<div class="text_10">{{ ess.publishTime | format }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="scyl">
					赛程一览
				</div>

				<div class="table">
					<el-table :data="scheduleList" border style="width: 100%;"
						:header-cell-style="{ background: 'rgba(0, 173, 249, 0.38)' }" :row-class-name="tableRowClassName">
						<el-table-column prop="name" label="赛事名称" align="center">
							<template slot-scope="scope">
								<!-- <div class="getname"  @click="getname(scope.row.name)">{{ scope.row.name }}</div> -->
								<div class="getname">{{ scope.row.name }}</div>
							</template>
						</el-table-column>
						<el-table-column prop="address" label="地点" align="center">
						</el-table-column>
						<el-table-column label="时间" align="center">
							<template slot-scope="scope">
								<div v-if="scope.row.isJSON">
									<div v-for="(item, index) in scope.row.start_time">
										{{ getTime(item) }}
									</div>
								</div>
								<div v-if="!scope.row.isJSON">
									{{ scope.row.start_time | format }}
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>

				<div style="margin-top: 45PX;" v-if="momentsList && momentsList.length > 0">
					<div class="jcxj">
						<div class="jcxj-box">
							<p>精彩瞬间</p>
							<img src="../../assets/eventtheme/title6.png" alt="">
						</div>
						<div class="jcxj-gd" @click="goDingyue2('精彩瞬间', id)">更多&gt;&gt;</div>
					</div>
					<div class="wonderful-box">
						<div class="wonderful" v-for="(item, index) in momentsList" v-if="index < 4" :key="index"
							@click="jump(item.id, 1)">
							<img class="img" :src="item.imgUrl" alt="">
							<p class="name">{{ item.title }}</p>
						</div>
					</div>
				</div>
				<div style="height: 100PX;"></div>
			</div>

			<div v-else>
				<div class="statistics" style="background: #fff;    position: relative;margin-top: -50PX;">
					<div class="box_4 flex-col"
						:style="{ 'background': '#fff', 'height': 'auto', 'min-height': '500PX', 'margin-bottom': concentrateList.length > 0 ? '45PX' : 0 }">
						<div class="section_14 flex-row justify-between">
							<div class="block_14 flex-col justify-between">
								<div class="group_2 flex-col"></div>
								<span class="text_7">{{ concentratetitle }}</span>
							</div>
							<div class="section_3 flex-row justify-end" @click="goDingyue(res.name, res)">
								<span class="text_8"></span>
								<div class="group_3 flex-col"></div>
							</div>
						</div>
						<div class="text-wrapper_104 flex-row justify-between" v-for="(item, index) in concentrateList" :key="index"
							@click="jump2(item.id, concentratetitle)">
							<div class="text_9">{{ item.title }}</div>
							<div class="text_10">{{ item.publishTime | format }}</div>
						</div>
					</div>

					<div class="paging" v-if="concentrateList.length > 0">
						<pagination :total="total" @currentchange="currentchange" :go="10" :pageSize="10">
						</pagination>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import Meta from 'vue-meta'
Vue.use(Meta)
import carousel from '@/components/carousel.vue'
import achievementimg from '../../assets/eventtheme/rectangle.png'
import achievementimg1 from '../../assets/eventtheme/rectangle1.png'
import Calendar from '@/components/calendar.vue'
export default {
	name: 'eventTheme2',
	components: {
		Calendar,
		carousel
	},
	data() {
		return {
			achievement: achievementimg,
			achievement1: achievementimg1,
			tableData: [],
			allData: [],
			allData1: [],
			articles: [],
			navindex: 1,
			cards: [],
			mchList: [],
			statisticalTime: '',
			display: true,
			concentrateList: [],
			concentratetitle: '',
			navList: [],
			info: {
				id: '',
				pageNum: 1,
				pageSize: 10,
			},
			total: 0,
			topImg: '',
			scrollIndex: 1,
			$lunbo: null,
			$circle: null,
			$main: null,
			isAnimated: false,
			len: 0,
			arr: [],
			logo: '',
			title: '',
			infoDate: [],
			scheduleList: [],
			momentsList: [],
			id: '',
			medalyear: new Date().getFullYear()
		}
	},
	created() {
		this.logo = this.$route.query.logo
		this.title = this.$route.query.title

		document.title = this.$route.query.title + '-北京市体育竞赛管理和国际交流中心'
		this.$meta().refresh()

		if (!localStorage.getItem('isReloaded')) {
			localStorage.setItem('isReloaded', true);
			location.reload();
		} else {
			localStorage.removeItem('isReloaded');
		}
		this.$store.commit("EXIT_STATIONID", this.$route.query.id)
		if (this.$route.query.is_gray == 1) {

		} else if (this.$route.query.is_gray == 2) {

		}
		this.getIndexImgList()
		this.getTopBanner()
		this.getNav()
		this.getIndexColumn()
		this.medalRankingList()
		this.arrange()
	},
	activated() {
		this.$store.commit("EXIT_STATIONID", this.$route.query.id)
	},
	mounted() {

	},
	filters: {
		format(x) {
			if (x != null && x != '') {
				return x.split(" ")[0]
			}
		}
	},
	methods: {
		arrange() {
			let data = {
				stationId: this.$route.query.id
			}
			this.$api.matchInfoListAll(data).then(res => {
				res.data.data.forEach(item => {
					item.isJSON = this.isJSON(item.start_time)
					if (this.isJSON(item.start_time)) {
						item.start_time = JSON.parse(item.start_time)
					}
				})
				this.scheduleList = res.data.data
			})
		},
		isJSON(str) { // 判断是否json
			if (typeof str != 'string') { // 1、传入值必须是 字符串
				return false;
			}
			try {
				var obj = JSON.parse(str); // 2、仅仅通过 JSON.parse(str)，不能完全检验一个字符串是JSON格式的字符串
				if (typeof obj == 'object' && obj) { //3、还必须是 object 类型
					return true;
				} else {
					return false;
				}
			} catch (e) {
				return false;
			}
			return false;
		},
		getTime(dates) { // 时间转换
			let startDate = new Date(dates[0]);
			let endDate = new Date(dates[1]);
			let formattedStartDate =
				`${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
			let formattedEndDate =
				`${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
			if (formattedStartDate == formattedEndDate) {
				return `${formattedStartDate}`;
			} else {
				return `${formattedStartDate} 至 ${formattedEndDate}`;
			}
		},
		getIndexImgList() {
			this.$api.getIndexImgList().then((res) => {
				this.cards = res.data.data
			});
		},
		getTopBanner() {
			this.$api.getTopBanner({
				type: '1'
			}).then(res => {
				this.topImg = res.data.data[0].imgUrl
			})
		},
		getNav() {
			this.$api.getNav().then(res => {
				this.navList = res.data.data
			})
		},
		tableRowClassName({
			row,
			rowIndex
		}) {
			if (String(rowIndex / 2).indexOf(".") > -1) {
				return 'success-row';
			}
			return '';
		},
		getnav(index, item) {
			this.navindex = index
			this.articles = item.articles
		},
		homepage() {
			this.$router.push({
				path: '/'
			})
		},
		getIndexColumn() {
			this.$api.getIndexColumn(this.$route.query.id).then(res => {
				this.allData = res.data.data
				this.allData1 = res.data.data.slice(1)
				this.articles = res.data.data[0][0].articles

				for (let i in res.data.data) {
					for (let x in res.data.data[i]) {
						if (res.data.data[i][x].name == '精彩瞬间') {
							this.momentsList = res.data.data[i][x].articles
							this.id = res.data.data[i][x].id
						}
					}
				}

				this.allData1.forEach(item => {
					item.forEach(rss => {
						rss.articles.forEach(css => {
							css.title = css.title.replace(/<br>/g, ' ')
						})
					})
				})
				this.momentsList.forEach(item => {
					item.title = item.title.replace(/<br>/g, ' ')
				})
			})
		},
		jump(id, e) {
			if (e == 1) {
				if (this.$store.state.token == '') {
					this.$confirm('请登录后查看更多信息', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/login',
							query: {
								person: true,
								grp: false,
								refresh: new Date().getTime()
							}
						})
					}).catch(() => { });
					return
				}
			}
			let routeData = this.$router.resolve({
				path: '/articleDetails',
				query: {
					id: id,
					refresh: new Date().getTime()
				}
			});
			window.open(routeData.href, '_blank');
		},
		jump2(id, title) {
			if (title == '精彩瞬间') {
				if (this.$store.state.token == '') {
					this.$confirm('请登录后查看更多信息', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$store.commit("EXIT_TOKEN")
						this.$router.push({
							path: '/login',
							query: {
								person: true,
								grp: false,
								refresh: new Date().getTime()
							}
						})
					}).catch(() => { });
					return
				}
			}
			let routeData = this.$router.resolve({
				path: '/articleDetails',
				query: {
					id: id,
					refresh: new Date().getTime()
				}
			});
			window.open(routeData.href, '_blank');
		},
		medalRankingList() {
			this.$api.medalRankingList(this.$route.query.id, 2).then(res => {
				this.tableData = res.data.data.rankingList
				this.statisticalTime = res.data.data.statisticalTime
				this.medalyear = res.data.data.year
			})
		},
		goDingyue(title, row) {
			this.concentratetitle = title
			this.display = false
			this.info.id = row.id
			this.getArticleListById()
		},
		goDingyue2(title, id) {
			this.concentratetitle = title
			this.display = false
			this.info.id = id
			this.getArticleListById()
		},
		currentchange(val) {
			this.info.pageNum = val
			this.getArticleListById()
		},
		getArticleListById() {
			this.$api.getArticleListById(this.info).then(res => {
				this.concentrateList = res.data.data.list
				this.total = res.data.data.total
			})
		},
		getname(row) {
			let title = ''
			if (row == '2023年北京市青少年篮球锦标赛（甲组）') {
				title = row.replace('（甲组）', '')
			} else if (row == '2023年北京市青少年篮球锦标赛（乙组）') {
				title = row.replace('（乙组）', '')
			} else if (row == '2023年北京市青少年篮球锦标赛（丙组）') {
				title = row.replace('（丙组）', '')
			} else {
				title = row
			}

			let routeData = this.$router.resolve({
				path: '/eventquery',
				query: {
					id: '847',
					pid: '720',
					refresh: new Date().getTime(),
					matchName: title
				}
			});
			window.open(routeData.href, '_blank');
		}
	}
}
</script>
<style lang="scss" scoped>
.head {
	width: 100%;
	background: #fff;
}

.back-box {
	background: url('../../assets/eventtheme/index7.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
}

.head .text {
	position: absolute;
	top: 112PX;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.head .text .logo {
	width: 300PX;
	height: 200PX;
}

.head .text .title {
	font-size: 41PX;
	font-weight: normal;
	color: #fff;
	margin-top: 10PX;
}

img {
	width: 100%;
	height: 100%;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
	object-fit: cover;
}

.back {
	width: 100%;
	min-height: 500PX;
}

.navigation {
	// height: 164PX;
	width: 100%;
	/* background: #FFFFFF; */
	display: flex;
	justify-content: center;
	cursor: pointer;
	top: -50PX;
	position: relative;
}

.piece {
	width: 201PX;
	height: 50PX;
	// background: linear-gradient(0deg, #144c82, #029f74);
	// box-shadow: 1PX 1PX 0PX 0PX #FFFFFF;
	background: #fff;
	border-radius: 4PX;
	display: flex;
	margin-right: 28PX;
}

.piece .court {
	width: 65PX;
	height: 50PX;
	margin-top: 36PX;
	margin-left: 29PX;
}

.piece .name {
	font-family: Microsoft YaHei;
	font-weight: 400;
	font-size: 16PX;
	color: #000;
	text-shadow: 0PX 0PX 1PX rgba(0, 0, 0, 0.35);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 201PX;
}

.carousel {
	width: 1200PX;
	margin: 0 auto;
	display: flex;
}

.medaltally {
	margin-left: 18PX;

	p {
		position: absolute;
		margin: 11PX 0 13PX 19PX;
		font-size: 16PX;
		font-family: Microsoft YaHei;
		font-weight: 600;
		color: #000000;
	}

	img {
		width: 539PX;
		height: 43PX;
	}

	.statisticalTime {
		position: absolute;
		width: 539PX;
		text-align: right;
		height: 43PX;
		line-height: 54PX;
		color: #fff;
		padding-right: 10PX;
	}
}

.lun {
	width: 643PX;
	height: 459PX;
	border-radius: 16PX;
}

.lun .main {
	position: relative;
	overflow: hidden;
	border-radius: 16PX;
}

.lun .lun_bo {
	overflow: hidden;
	position: absolute;
}

.lun .lun_bo:hover {
	cursor: pointer;
}

.lun .lun_bo img {
	display: block;
	float: left;
	border-radius: 16PX;
	height: 459PX;
}

.lun .btn_demo {
	width: 643PX;
	height: 72PX;
	background: rgba(0, 0, 0, 0.38);
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1000;
	padding: 0 40PX;
	justify-content: space-between;
	border-bottom-left-radius: 16PX;
	border-bottom-right-radius: 16PX;
}

.lun .btn_disc {
	display: flex;
	justify-content: center;
	margin-top: -110PX;
}

.lun .desc {
	color: #fff;
	text-align: center;
	font-size: 18PX;
	font-weight: 500;
	line-height: 72PX;
}

.lun a {
	width: 13PX;
	height: 13PX;
	background: #FFFFFF;
	border-radius: 50%;
	display: flex;
	margin-right: 10PX;
	cursor: pointer;
	margin-top: 13PX;
}

.lun a:hover {
	cursor: pointer;
}

.lun .direct {
	background: transparent;
	position: absolute;
	top: 200PX;
	height: 30PX;
	line-height: 25PX;
	text-align: center;
	font-size: 40PX;
	padding: 3PX;
	border: 4PX solid #fff;
	color: #fff;
	font-weight: 700;
	display: none;
}

.lun .left {
	left: 70PX;
}

.lun .right {
	right: 70PX;
}

.lun .direct:hover {
	cursor: pointer;
}

.block_8 {
	width: 532PX;
	height: 379PX;
	margin-left: 31PX;
}

.group_8 {
	height: 48PX;
	display: flex;
	margin-left: 30PX;
}

.rectangle {
	width: 218PX;
	height: 48PX;
	cursor: pointer;
	margin-right: 27PX;
}

.rectangle img {
	width: 100%;
	height: 100%;
}

.rectangle .name {
	width: 218PX;
	height: 48PX;
	font-size: 20PX;
	font-weight: 500;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.left {
	margin-left: 27PX;
}

.box {
	background: #FFFFFF;
	border-radius: 25PX;
	padding: 14PX;
	padding-top: 38PX;
	padding-bottom: 38PX;
}

.text-wrapper_2 {
	height: 42PX;
	margin-left: 1PX;
	width: 96PX;
	line-height: 42PX;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1PX solid #D0D0D0;
}

.text_7 {
	width: 64PX;
	height: 17PX;
	overflow-wrap: break-word;
	color: rgba(51, 51, 51, 1);
	font-size: 16PX;
	font-family: MicrosoftYaHei-Bold;
	font-weight: bold;
	text-align: left;
	white-space: nowrap;
	line-height: 16PX;
}

.group_9 {
	background-color: rgba(208, 208, 208, 1);
	width: 528PX;
	height: 1PX;
}

.box_31 {
	width: 503PX;
	height: 19PX;
	margin-top: 15PX;
	display: flex;
	cursor: pointer;
}

.thumbnail_8 {
	width: 12PX;
	height: 12PX;
}

.text_20 {
	width: 305PX;
	height: 17PX;
	overflow-wrap: break-word;
	color: rgba(51, 51, 51, 1);
	font-size: 15PX;
	font-family: MicrosoftYaHei;
	font-weight: NaN;
	text-align: left;
	white-space: nowrap;
	line-height: 16PX;
	margin: 1PX 0 0 13PX;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
}

.text_20:hover a {
	color: #408ED6;
}

.text_21 {
	width: 80PX;
	height: 14PX;
	overflow-wrap: break-word;
	color: rgba(153, 153, 153, 1);
	font-size: 13PX;
	font-family: MicrosoftYaHei;
	font-weight: NaN;
	text-align: left;
	white-space: nowrap;
	line-height: 13PX;
	margin: 1PX 0 0 93PX;
}

.image_9 {
	border: 1PX dashed #CCD5D5;
	margin-top: 12PX;
}

.image_8 {
	background-color: rgba(208, 208, 208, 1);
	height: 1PX;
}

.statistics {
	width: 1200PX;
	margin: 0 auto;
	margin-top: 45PX;
}

.statistics img {
	width: 100%;
}

.statistics .title {
	display: flex;
	position: absolute;
	justify-content: space-between;
	align-items: center;
	width: 1200PX;
}

.statistics .title .name {
	font-size: 30PX;
	font-weight: bold;
	color: #0056F0;
	margin-top: 40PX;
	margin-left: 120PX;
}

.statistics .title .date {
	margin-top: 45PX;
	margin-right: 48PX;
	font-size: 21PX;
	font-weight: 500;
	color: #0077EC;
}

.statistics .title .border {
	border: 1PX solid #0084F8;
	border-radius: 15PX;
	padding: 4PX 15PX;
	margin-top: 40PX !important;
	cursor: pointer;
	z-index: 999999;
}

.table {
	width: 1200PX;
	overflow-y: auto;
	color: #000;
	margin: 20PX auto 0;
}

.table ::v-deep .el-table .success-row {
	background: rgba(0, 119, 236, 0.12);
}

/*滚动条样式*/
.table::-webkit-scrollbar {
	width: 10PX;
	position: absolute;
}

.table::-webkit-scrollbar-thumb {
	border-radius: 10PX;
	background: rgba(0, 132, 248, 0.32);
	box-shadow: 1PX 13PX 18PX 0PX rgba(81, 81, 81, 0.25)
}

.table::-webkit-scrollbar-track {
	border-radius: 10PX;
	background: rgba(166, 166, 166, 0.08);

}

.table ::v-deep .el-table thead tr {
	color: #000;
}

.table ::v-deep .el-table__body td {
	color: #000;
}

.table2 {
	position: absolute;
	margin-top: 20PX;
	height: 397PX;
	overflow-y: auto;
	color: #000;
	width: 539PX;
}

.table2 ::v-deep .el-table .success-row {
	background: rgba(0, 119, 236, 0.12);
}

/*滚动条样式*/
.table2::-webkit-scrollbar {
	width: 10PX;
	position: absolute;
}

.table2::-webkit-scrollbar-thumb {
	border-radius: 10PX;
	background: rgba(0, 132, 248, 0.32);
	box-shadow: 1PX 13PX 18PX 0PX rgba(81, 81, 81, 0.25)
}

.table2::-webkit-scrollbar-track {
	border-radius: 10PX;
	background: rgba(166, 166, 166, 0.08);

}

.custom-header {
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-header img {
	width: 30PX;
	height: 40PX;
	margin-right: 7PX;
}

// .table2 ::v-deep .el-table thead tr th:nth-child(2){
// 	background-image: linear-gradient(#F8B65C, #FFE71D, #FFBA5B) !important;
// }
// .table2 ::v-deep .el-table thead tr th:nth-child(3){
// 	background-image: linear-gradient(#EEEDEC, #D1CCCA, #F0EFEE) !important;
// }
// .table2 ::v-deep .el-table thead tr th:nth-child(4){
// 	background-image: linear-gradient(#FFE5BB, #DE9D76, #FFF7D9) !important;
// }

.table2 ::v-deep .el-table thead tr {
	color: #000;
}

.table2 ::v-deep .el-table__body td {
	color: #000;
}

.project-case {
	width: 1300PX;
	position: absolute;
	padding: 105PX 73PX 70PX 62PX;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	cursor: pointer;
}

.project-box {
	width: 512.3PX;
	height: 125PX;
	background-image: url('../../assets/eventtheme/back3.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin-bottom: 47PX;
	margin-right: 61PX;
}

.project-box:nth-child(2n) {
	margin-right: 0;
}

.project {
	display: flex;
}

.project img {
	width: 97.7PX;
	height: 97.7PX;
	margin-top: 14PX;
	margin-left: 37PX;
	border-radius: 50%;
	flex-shrink: 0;
}

.project .title-box {
	margin-left: 37PX;
	margin-top: 27PX;
	padding-right: 25PX;
}

.project .name {
	font-size: 25PX;
	font-weight: 500;
	color: #000000;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	width: 200PX;
}

.project .brief {
	height: 40PX;
	font-size: 14PX;
	font-weight: 400;
	color: #000000;
	margin-top: 5PX;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.calendar-box {
	position: absolute;
	padding-top: 112PX;
}

.calendar-box .calendar {
	display: flex;
}

.calendar-box .calendar .flrex {
	margin-left: 39PX;
	margin-top: 100PX;
	overflow-y: auto;
	height: 298PX;
	width: 500PX;
}

.calendar-box .calendar .flrex .information .rubric {
	width: 460PX;
	height: 106PX;
	font-size: 26PX;
	font-weight: bold;
	color: #0086F8;
	border-radius: 14PX;
}

.rubricbox {
	height: 92PX;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	padding-top: 20PX;
	margin-left: 65PX;
	margin-right: 54PX;
}

.calendar-box .calendar .flrex .information .date {
	font-size: 27PX;
	font-weight: 400;
	color: #2F3F5A;
	margin-top: 41PX;
	display: flex;
	align-items: center;
	margin-left: 68PX;
}

.calendar-box .calendar .flrex .information .round {
	width: 12PX;
	height: 12PX;
	background: rgba(0, 0, 0, 0.28);
	border-radius: 50%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	width: 390PX;
}

.calendar-box .calendar .flrex .information .location {
	font-size: 27PX;
	font-weight: 400;
	color: #2F3F5A;
	margin-top: 29PX;
	display: flex;
	align-items: center;
	margin-left: 68PX;
	padding-bottom: 50PX;
}

.roundtext {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	width: 390PX;
}

.calendar-box .calendar .flrex .information .round {
	width: 12PX;
	height: 12PX;
	background: rgba(0, 0, 0, 0.28);
	border-radius: 50%;
}

/*滚动条样式*/
.calendar-box .calendar .flrex::-webkit-scrollbar {
	width: 18PX;
	position: absolute;
}

.calendar-box .calendar .flrex::-webkit-scrollbar-thumb {
	border-radius: 8PX;
	background: #0086F8;
}

.calendar-box .calendar .flrex::-webkit-scrollbar-track {
	border-radius: 8PX;
	background: rgba(68, 68, 68, 0.14);

}

.calendar-box .calendar .assembly {
	padding-left: 40PX;
}

.specific {
	width: 1156PX;
	font-size: 15PX;
	color: #333333;
	position: absolute;
	margin-top: 134PX;
	padding: 0 67PX 0 53PX;
	height: 450PX;
	overflow-y: auto
}

.specific::-webkit-scrollbar {
	width: 17PX;
	position: absolute;
}

.specific::-webkit-scrollbar-thumb {
	border-radius: 10PX;
	background: rgba(0, 132, 248, 0.32);
	box-shadow: 1PX 13PX 18PX 0PX rgba(81, 81, 81, 0.25)
}

.specific::-webkit-scrollbar-track {
	border-radius: 10PX;
	background: rgba(166, 166, 166, 0.08);

}

.specific ul li {
	display: flex;
	justify-content: space-between;
	margin-bottom: 23PX;
}

.specific ul li span {
	color: #999999;
}

.rounddot {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.rounddot:hover a {
	color: #408ED6;
}

.rounddota {
	width: 900PX;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
}

.round {
	width: 7PX;
	height: 7PX;
	border-radius: 100%;
	background: #000;
	box-shadow: 0 2PX 4PX 0 rgba(0, 0, 0, .2);
	margin-right: 10PX;

}

.wonderful-box {
	display: flex;
	padding: 0 0 0 54PX;
	cursor: pointer;
	margin-top: 20PX;
}

.wonderful {
	width: 278PX;
	height: 171PX;
	margin-right: 27PX;
}

.wonderful .img {
	width: 100%;
	height: 100%;
}

.wonderful .name {
	width: 278PX;
	background: rgba(0, 0, 0, 0.55);
	font-size: 15PX;
	font-weight: bold;
	color: #F8F9FD;
	padding: 20PX 10PX;
	margin-top: -60PX;
	bottom: 0;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	position: relative;
	z-index: 999;
}

.return {
	right: 42PX;
	width: 90PX;
	height: 206PX;
	background: #D5ECFF;
	border-radius: 15PX;
	cursor: pointer;
	z-index: 999999;

	top: 50%;
	transform: translateY(-50%);
	position: fixed;
}

.return img {
	width: 61PX;
	height: 58PX;
	margin-top: 23PX;
	margin-left: 18PX;
}

.return img {
	width: 61PX;
	height: 58PX;
	margin-top: 23PX;
	margin-left: 18PX;
}

.return .home {
	font-size: 25PX;
	font-weight: 400;
	color: #000000;
	margin-left: 20PX;
	margin-top: 24PX;
}

.paging {
	display: flex;
	align-items: center;
	margin-left: 24PX;
	padding-bottom: 40PX;
}

.paging span {
	margin-right: 5PX;
}

::v-deep .el-pager li.active {
	color: #003399;
}

.box_4 {
	width: 1200PX;
	height: 351PX;
	margin-bottom: 45PX;
}

.section_14 {
	width: 1200PX;
	height: 53PX;
	display: flex;
	margin-bottom: 20PX;
}

.block_14 {
	width: 152PX;
	height: 40PX;
}

.group_2 {
	background-color: rgba(255, 135, 21, 1);
	width: 152PX;
	height: 2PX;
}

.text_7 {
	width: 133PX;
	height: 26PX;
	overflow-wrap: break-word;
	color: rgba(0, 94, 173, 1);
	font-size: 24PX;
	font-family: MicrosoftYaHei;
	font-weight: NaN;
	text-align: left;
	white-space: nowrap;
	line-height: 53PX;
	margin: 12PX 0 0 13PX;
}

.section_3 {
	background-color: rgba(227, 227, 227, 1);
	width: 100%;
	height: 53PX;
	display: flex;
}

.text_8 {
	width: 84PX;
	height: 23PX;
	overflow-wrap: break-word;
	color: rgba(0, 0, 0, 1);
	font-size: 23PX;
	font-family: MicrosoftYaHei;
	font-weight: NaN;
	text-align: left;
	white-space: nowrap;
	margin: 12PX 0 0 937PX;
	cursor: pointer;
}

.group_3 {
	background-color: rgba(255, 167, 83, 1);
	width: 12PX;
	height: 53PX;
	margin-left: 15PX;
}

.text-wrapper_104 {
	width: 1150PX;
	height: 19PX;
	margin-left: 24PX;
	margin-bottom: 20PX;
	display: flex;
	justify-content: space-between;
}

.text_9 {
	width: 1000PX;
	height: 22PX;
	overflow-wrap: break-word;
	color: rgba(111, 111, 111, 1);
	font-size: 18PX;
	font-family: MicrosoftYaHei;
	font-weight: NaN;
	text-align: left;
	white-space: nowrap;
	margin-top: 1PX;
	cursor: pointer;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
}

.text_10 {
	width: 107PX;
	height: 22PX;
	overflow-wrap: break-word;
	color: rgba(147, 147, 147, 1);
	font-size: 18PX;
	font-family: MicrosoftYaHei;
	font-weight: NaN;
	text-align: left;
	white-space: nowrap;
	cursor: pointer;
}

.jcxj {
	display: flex;
	margin: 0 auto;
	width: 1200PX;
	align-items: center;
	justify-content: space-between;

	.jcxj-box {

		img {
			width: 1130PX;
			height: 43PX;
		}

		p {
			position: absolute;
			margin: 6PX 0 0 38PX;
			font-size: 22PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
		}

	}

	.jcxj-gd {
		cursor: pointer;
		font-size: 17PX;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #0567AC;
		margin-top: 10PX;
	}
}

// .getname{
// cursor: pointer;
// color: #409eff;
// }

.scyl {
	width: 1200PX;
	margin: 0 auto;
	height: 82PX;
	background: linear-gradient(90deg, #01a673, #1c2789);
	font-size: 32PX;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
